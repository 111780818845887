import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "react"
    }}>{`React`}</h1>
    <h2 {...{
      "id": "quickstart"
    }}>{`Quickstart`}</h2>
    <h3 {...{
      "id": "set-up-a-ghostport-project"
    }}>{`Set up a GhostPort project`}</h3>
    <p>{`Follow the instructions from the `}<a parentName="p" {...{
        "href": "/"
      }}>{`Getting Started`}</a>{` page.`}</p>
    <h3 {...{
      "id": "install-the-use-ghostport-npm-module"
    }}>{`Install the `}<inlineCode parentName="h3">{`use-ghostport`}</inlineCode>{` NPM module`}</h3>
    <p>{`Install the `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/use-ghostport"
      }}><inlineCode parentName="a">{`use-ghostport`}</inlineCode></a>{` NPM module.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npm install --save use-ghostport
`}</code></pre>
    <h3 {...{
      "id": "wrap-the-app-in-ghostportprovider"
    }}>{`Wrap the app in `}<inlineCode parentName="h3">{`GhostPortProvider`}</inlineCode></h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { GhostPortProvider } from 'use-ghostport';

<GhostPortProvider token={"YOUR_TOKEN"}>
    <App />
</GhostPortProvider>
`}</code></pre>
    <h3 {...{
      "id": "use-a-flag-with-usefeature-hook"
    }}>{`Use a flag with `}<inlineCode parentName="h3">{`useFeature`}</inlineCode>{` hook`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { useFeature } from 'use-ghostport';

export function App() {
    const showHeader = useFeature('showHeader', false, true);

    return (
        <div>
            {showHeader && (
                <h1>The header is visible</h1>
            )}
            Main content
        </div>
    );
}
`}</code></pre>
    <h2 {...{
      "id": "api"
    }}>{`API`}</h2>
    <h3 {...{
      "id": "ghostportprovider"
    }}><inlineCode parentName="h3">{`GhostPortProvider`}</inlineCode></h3>
    <p>{`The provider that must be present at a higher level in the React tree than any calls to `}<inlineCode parentName="p">{`useFeature`}</inlineCode>{`.`}</p>
    <p>{`Props:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`token`}</inlineCode>{` - An environment token obtained from GhostPort.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`userKey`}</inlineCode>{` (optional) - A value uniquely identifying a user in your app.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`properties`}</inlineCode>{` (optional) - Arbitrary object with primitive values to use for feature targeting rules.`}</li>
    </ul>
    <h3 {...{
      "id": "usefeature"
    }}><inlineCode parentName="h3">{`useFeature`}</inlineCode></h3>
    <p>{`A hook to obtain the value of a feature flag, by its key.`}</p>
    <p>{`Arguments:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`flagKey`}</inlineCode>{` - The key of the flag to obtain the value from.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`defaultValue`}</inlineCode>{` - The value to return if the key does not exist, or if there is an error reaching the GhostPort API.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`live`}</inlineCode>{` (optional boolean, default `}<inlineCode parentName="li">{`false`}</inlineCode>{`) - True if the feature value should be pushed immediately when updated in the GhostPort UI.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      